import EntityMapper from "@/components/inventory/BatchTransfer/EntityMappers/EntityMapper.abstract";
import {
  BatchTransfer,
  ExternalBatchTransfer,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";
export default class QuoteMapper extends EntityMapper {
  public mapInput(
    quote: ExternalBatchTransfer.Input.QuoteTransfer.Quote
  ): BatchTransfer.Quote {
    return {
      id: quote.id,
      barcode: quote.barcode,
      transfer_type: TRANSFER_TYPES.QUOTE,
      destination_type: quote.targetable_type,
      destination_id: quote.targetable_id,
      destination: quote.targetable,
      pricing_enabled: true,
      status: quote.status,
      location_id: quote.location_id,
      prices: {
        subTotal: quote.sub_total,
        total: quote.total,
        discount: {
          description: quote.discount.name,
          amount: quote.discount.amount,
          type: quote.discount.type,
          postTaxDiscount: quote.discount.post_tax,
          value: quote.discount.amount
        },
        fee: {
          description: quote.fees.name,
          amount: quote.fees.amount,
          type: quote.fees.type,
          postTaxFee: quote.fees.post_tax,
          value: quote.fees.amount
        },
        taxCollected: quote.tax
      },
      payment_methods_breakdown: {
        initial: quote.total,
        payment_and_methods: [],
        outstanding: 0
      },
      quote_number: quote.barcode,
      updated_at: quote.updated_at,
      request_on_or_before: quote.request_at,
      notes:
        quote.notes && quote.notes[0] && !!quote.notes[0].note
          ? quote.notes.map(note => {
              return {
                id: note.id,
                name: `outbound-transfer-${quote.barcode}`,
                title: `outbound-transfer-${quote.barcode}-${quote.updated_at}`,
                note: note.note
              };
            })
          : [],
      user_id: quote.user_id,
      user: quote.user,
      // @ts-ignore --> Diference between API and front end
      items: quote.quote_items
        ? quote.quote_items.map(item => {
            return {
              id: item.id,
              persisted: true,
              sku: item.sku,
              product: item.product,
              batch_uid: item.batch_uid,
              batch: item.batch,
              quantity: item.quantity_value,
              tax_category_id: item.tax_category_id,
              tax_count: {
                pre_excise: 0,
                excise: 0,
                post_excise: 0,
                per_transaction: 0
              },
              prices: {
                price_per_unit: item.price_per_unit,
                cost_per_unit: item.cost_per_unit,
                taxCollected: item.tax_total,
                // @ts-ignore --> Diference between API and front end
                subTotal: item.sub_total,
                total: item.total,
                discount: {
                  description: item.discount.name,
                  amount: item.discount.amount,
                  type: item.discount.type,
                  postTaxDiscount: item.discount.post_tax,
                  value: item.discount.amount
                },
                fee: {
                  description: item.fees.name,
                  amount: item.fees.amount,
                  type: item.fees.type,
                  postTaxFee: item.fees.post_tax,
                  value: item.fees.amount
                }
              }
            };
          })
        : []
    };
  }
  public mapOutput(
    quote: BatchTransfer.Quote
  ): ExternalBatchTransfer.Output.QuoteTransfer.Quote {
    return {
      targetable_type: quote.destination_type,
      targetable_id: quote.destination_id,
      request_at: quote.request_on_or_before,
      sub_total: quote.prices.subTotal,
      tax: quote.prices.taxCollected,
      total: quote.prices.total,
      payments: [],
      discount: {
        name: quote.prices.discount.description,
        type: quote.prices.discount.type,
        amount: quote.prices.discount.amount,
        post_tax: quote.prices.discount.postTaxDiscount
      },
      fees: {
        name: quote.prices.fee.description,
        type: quote.prices.fee.type,
        amount: quote.prices.fee.amount,
        post_tax: quote.prices.fee.postTaxFee
      },
      notes:
        quote.notes && quote.notes[0] && !!quote.notes[0].note
          ? quote.notes.map(note => {
              return {
                id: note.id,
                name: `quote-${quote.barcode}`,
                title: `quote-${quote.barcode}-${quote.updated_at}`,
                note: note.note
              };
            })
          : [],
      quoteItems: quote.items
        ? quote.items.map(item => {
            return {
              id: item.persisted ? item.id : null,
              sku: item.product!.sku,
              _destroy: item.destroy,
              batch_uid:
                item.batch && item.batch.batch_uid
                  ? item.batch.batch_uid
                  : item.batch_uid,
              quantity_value: item.quantity,
              cost_per_unit: item.prices.cost_per_unit,
              tax_category_id:
                item.tax_category_id && item.tax_category_id > -1
                  ? item.tax_category_id
                  : null,
              price_per_unit: item.prices.price_per_unit,
              tax_total: item.prices.taxCollected,
              total: item.prices.total,
              sub_total: item.prices.subTotal,
              discount: {
                name: item.prices.discount.description,
                type: item.prices.discount.type,
                amount: item.prices.discount.amount,
                post_tax: item.prices.discount.postTaxDiscount
              },
              fees: {
                name: item.prices.fee.description,
                type: item.prices.fee.type,
                amount: item.prices.fee.amount,
                post_tax: item.prices.fee.postTaxFee
              }
            };
          })
        : []
    };
  }
}
