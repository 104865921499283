import {
  BatchTransfer,
  ExternalBatchTransfer,
  PRODUCT_UNIT,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";
import EntityMapper from "./EntityMapper.abstract";

export default class PurchaseOrderMapper extends EntityMapper {
  public mapInput(
    order: ExternalBatchTransfer.Input.PurchaseOrderTransfer.PurchaseOrder
  ): BatchTransfer.PurchaseOrder {
    return ({
      id: order.id,
      barcode: order.barcode,
      transfer_type: TRANSFER_TYPES.PURCHASE_ORDER,
      source_type: order.sourceable_type,
      source_id: order.sourceable_id,
      status: order.status,
      notes:
        order.notes && !!order.notes.length
          ? order.notes
          : [
              {
                name: `purchase-order-${order.barcode}`,
                title: `purchase-order-${order.barcode}`,
                note: ""
              }
            ],
      pricing_enabled: true,
      prices: {
        subTotal: order.sub_total,
        total: order.total,
        taxCollected: order.tax,
        discount: {
          description: order.discount.name,
          amount: order.discount.amount,
          type: order.discount.type,
          postTaxDiscount: order.discount.post_tax,
          value: order.discount.amount
        },
        fee: {
          description: order.fees.name,
          amount: order.fees.amount,
          type: order.fees.type,
          postTaxFee: order.fees.post_tax,
          value: order.discount.amount
        }
      },
      purchase_order_number: order.barcode,
      updated_at: order.updated_at,
      request_on_or_before: order.request_at,
      source: order.sourceable,
      user_id: order.user_id,
      user: order.user,
      items: order.purchase_order_items
        ? order.purchase_order_items.map(i => {
            return {
              id: i.id,
              sku: i.sku,
              persisted: true,
              product: i.product,
              tax_category_id: i.tax_category_id,
              tax_count: {
                pre_excise: 0,
                excise: 0,
                post_excise: 0,
                per_transaction: 0
              },
              quantity: i.quantity_value,
              batch_id: "",
              usable_weight_value: i.usable_weight_value,
              usable_weight_unit: i.usable_weight_unit,
              unit: i.product.unit,
              weight_per_unit_value: i.weight_per_unit_value,
              weight_per_unit_unit: i.weight_per_unit_unit,
              prices: {
                discount: {
                  description: i.discount.name,
                  amount: i.discount.amount,
                  type: i.discount.type,
                  postTaxDiscount: i.discount.post_tax,
                  value: i.discount.amount
                },
                fee: {
                  description: i.fees.name,
                  amount: i.fees.amount,
                  type: i.fees.type,
                  postTaxFee: i.fees.post_tax,
                  value: i.fees.amount
                },
                subTotal: 0,
                total: i.total,
                taxCollected: i.tax_total,
                price_per_unit: i.price_per_unit,
                cost_per_unit: i.cost_per_unit
              }
            };
          })
        : []
    } as unknown) as BatchTransfer.PurchaseOrder;
  }
  public mapOutput(
    order: BatchTransfer.PurchaseOrder
  ): ExternalBatchTransfer.Output.PurchaseOrderTransfer.PurchaseOrder {
    return {
      sourceable_type: order.source_type,
      sourceable_id: order.source_id!,
      request_at: order.request_on_or_before,
      sub_total: order.prices.subTotal,
      tax: order.prices.taxCollected,
      total: order.prices.total,
      discount: {
        name: order.prices.discount.description,
        type: order.prices.discount.type,
        amount: order.prices.discount.amount,
        post_tax: order.prices.discount.postTaxDiscount
      },
      fees: {
        name: order.prices.fee.description,
        type: order.prices.fee.type,
        amount: order.prices.fee.amount,
        post_tax: order.prices.fee.postTaxFee
      },
      notes:
        order.notes && order.notes[0] && !!order.notes[0].note
          ? order.notes.map(note => {
              return {
                id: note.id,
                name: `purchase-order-${order.barcode}`,
                title: `purchase-order-${order.barcode}-${order.updated_at}`,
                note: note.note
              };
            })
          : [],
      purchaseOrderItems: order.items.map(i => {
        return {
          id: i.persisted ? i.id : null,
          _destroy: i.destroy,
          sku: i.sku || i.product!.sku,
          quantity_value: i.quantity,
          price_per_unit: i.prices.price_per_unit,
          cost_per_unit: i.prices.cost_per_unit,
          subtotal: i.prices.subTotal,
          total: i.prices.total,
          tax_total: i.prices.taxCollected,
          tax_category_id:
            i.tax_category_id && i.tax_category_id > -1
              ? i.tax_category_id
              : null,
          quantity_unit: i.product && i.product.unit,
          usable_weight_value: i.usable_weight_value || 0,
          usable_weight_unit: i.usable_weight_unit,
          weight_per_unit_value: i.weight_per_unit_value || 0,
          weight_per_unit_unit: i.weight_per_unit_unit,
          discount: {
            name: i.prices.discount.description,
            type: i.prices.discount.type,
            amount: i.prices.discount.amount,
            post_tax: i.prices.discount.postTaxDiscount
          },
          fees: {
            name: i.prices.fee.description,
            type: i.prices.fee.type,
            amount: i.prices.fee.amount,
            post_tax: i.prices.fee.postTaxFee
          }
        };
      })
    };
  }
}
