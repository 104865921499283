import EntityMapper from "@/components/inventory/BatchTransfer/EntityMappers/EntityMapper.abstract";
import QuoteMapper from "@/components/inventory/BatchTransfer/EntityMappers/Quote.mapper";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES,
  ExternalBatchTransfer,
  PRODUCT_UNIT,
  TRANSFER_STATUS,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";

export default class OutboundTransferMapper extends EntityMapper {
  protected quoteMapper = new QuoteMapper();
  public mapInput(
    transfer: ExternalBatchTransfer.Input.OutboundTransfer.Outbound
  ): BatchTransfer.OutboundTransfer {
    return {
      id: transfer.id,
      barcode: transfer.transfer_id,
      transfer_type: TRANSFER_TYPES.OUTBOUND,
      transferable_type: transfer.transferable_type,
      transferable_id: transfer.transferable_id,
      transferable: transfer.transferable
        ? this.quoteMapper.mapInput(
            transfer.transferable as ExternalBatchTransfer.Input.QuoteTransfer.Quote
          )
        : undefined,
      status: transfer.status,
      is_payable: transfer.is_payable,
      destination_type: transfer.sourceable_type,
      destination_id: transfer.sourceable_id,
      destination: transfer.sourceable,
      location_id: transfer.location_id,
      location: transfer.location,
      user_id: transfer.user_id,
      user: transfer.user,
      updated_at: transfer.updated_at,
      notes:
        transfer.notes && transfer.notes[0] && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `outbound-transfer-${transfer.transfer_id}`,
                title: `outbound-transfer-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      // @ts-ignore --> Diference between API and front end
      items: transfer.transfer_items
        ? transfer.transfer_items.map(item => {
            return {
              id: item.id,
              persisted: true,
              sku: item.product!.sku,
              product: item.product,
              batch_uid: item.batch_uid,
              batch: item.batch,
              tax_category_id: item.tax_category_id,
              tax_count: {
                pre_excise: 0,
                excise: 0,
                post_excise: 0,
                per_transaction: 0
              },
              quantity: item.quantity_value,
              prices: {
                price_per_unit: item.price_per_unit,
                cost_per_unit: item.cost_per_unit,
                subTotal: 0,
                total: item.total,
                taxCollected: item.tax_total,
                discount: {
                  description: item.discount.name,
                  amount: item.discount.amount,
                  type: item.discount.type,
                  postTaxDiscount: item.discount.post_tax,
                  value: item.discount.amount
                },
                fee: {
                  description: item.fees.name,
                  amount: item.fees.amount,
                  type: item.fees.type,
                  postTaxFee: item.fees.post_tax,
                  value: item.fees.amount
                }
              },
              expiration_date: item.expiration_date,
              usable_weight_value: item.usable_weight_value,
              usable_weight_unit: item.usable_weight_unit,
              weight_per_unit_value: item.weight_per_unit_value,
              weight_per_unit_unit: item.weight_per_unit_unit,
              unit: item.quantity_unit,
              lab_results: {
                laboratory_id: 0,
                testing_date: "",
                results: []
              }
            };
          })
        : [],
      pricing_enabled: true,
      prices: {
        total: transfer.total,
        subTotal: transfer.sub_total,
        discount: {
          description: transfer.discount.name,
          amount: transfer.discount.amount,
          type: transfer.discount.type,
          postTaxDiscount: transfer.discount.post_tax,
          value: transfer.discount.amount
        },
        fee: {
          description: "",
          amount: 0,
          type: DISCOUNT_FEE_TYPES.FIXED,
          postTaxFee: true,
          value: 0
        },
        taxCollected: transfer.tax
      },
      payment_methods_breakdown: {
        initial: 0,
        payment_and_methods: transfer.payments.map(payment => {
          return {
            type: payment.payment_method_id,
            applied: payment.amount,
            remaining: 0
          };
        }),
        outstanding: 0
      },
      manifest: transfer.manifest
    };
  }
  public mapOutput(
    transfer: BatchTransfer.OutboundTransfer
  ): ExternalBatchTransfer.Output.OutboundTransfer.Outbound {
    return {
      id: transfer.id,
      type: TRANSFER_TYPES.OUTBOUND,
      transferable_type: transfer.transferable_type,
      transferable_id:
        transfer.transferable_id > -1 ? transfer.transferable_id : undefined,
      sourceable_type: transfer.destination_type,
      sourceable_transfer_type: transfer.destination_type,
      sourceable_id: transfer.destination_id,
      is_payable: transfer.is_payable,
      sub_total: transfer.prices.subTotal,
      total: transfer.prices.total,
      tax: transfer.prices.taxCollected,
      status: TRANSFER_STATUS.PENDING,
      payments: transfer.payment_methods_breakdown.payment_and_methods.map(
        payment => {
          return {
            payment_method_id: payment.type,
            amount: payment.applied
          };
        }
      ),
      discount: {
        name: transfer.prices.discount.description,
        amount: transfer.prices.discount.amount,
        post_tax: transfer.prices.discount.postTaxDiscount,
        type: transfer.prices.discount.type
      },
      fees: {
        name: transfer.prices.fee.description,
        amount: transfer.prices.fee.amount,
        post_tax: transfer.prices.fee.postTaxFee,
        type: transfer.prices.fee.type
      },
      notes:
        transfer.notes && transfer.notes[0] && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `outbound-transfer-${transfer.transfer_id}`,
                title: `outbound-transfer-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      transfer_items: transfer.items.map(item => {
        return {
          marijuana: item.product!.marijuana!,
          strain: item.product!.strain ? item.product!.strain!.name : "",
          batch_type_id: item.product!.batch_type_id,
          batch_uid: item.batch_uid,
          expiration_date: item.expiration_date,
          batch_price_level_id:
            item.batch_level_pricing && item.batch_level_pricing > -1
              ? item.batch_level_pricing
              : null,
          quantity_value: item.quantity,
          quantity_unit: item.product!.unit,
          price_per_unit: item.prices.price_per_unit,
          cost_per_unit: item.prices.cost_per_unit,
          subtotal: item.prices.subTotal,
          total: item.prices.total,
          tax_category_id:
            item.tax_category_id && item.tax_category_id > -1
              ? item.tax_category_id
              : null,
          tax_total: item.prices.taxCollected,
          discount: {
            name: item.prices.discount.description,
            amount: item.prices.discount.amount,
            post_tax: item.prices.discount.postTaxDiscount,
            type: item.prices.discount.type
          },
          fees: {
            name: item.prices.fee.description,
            amount: item.prices.fee.amount,
            post_tax: item.prices.fee.postTaxFee,
            type: item.prices.fee.type
          },
          sample: {
            laboratory_id: item.lab_results.laboratory_id,
            testing_date: item.lab_results.testing_date,
            results: [...item.lab_results.results]
          },
          usable_weight_value: item.usable_weight_value || null,
          usable_weight_unit:
            (item.usable_weight_value && item.usable_weight_unit) || null,
          weight_per_unit_value: item.weight_per_unit_value,
          weight_per_unit_unit: item.weight_per_unit_unit
        };
      })
    };
  }
  public convertQuote(
    quote: BatchTransfer.Quote
  ): Partial<BatchTransfer.OutboundTransfer> {
    return {
      transfer_type: quote.transfer_type,
      items: quote.items.map(item => {
        return {
          ...item,
          expiration_date: "",
          lab_results: {
            laboratory_id: 0,
            testing_date: "",
            results: []
          }
        };
      }),
      prices: {
        subTotal: quote.prices.subTotal,
        total: quote.prices.total,
        taxCollected: quote.prices.taxCollected,
        discount: {
          description: quote.prices.discount.description,
          amount: quote.prices.discount.amount,
          type: quote.prices.discount.type,
          postTaxDiscount: quote.prices.discount.postTaxDiscount,
          value: 0
        },
        fee: {
          description: quote.prices.fee.description,
          amount: quote.prices.fee.amount,
          type: quote.prices.fee.type,
          postTaxFee: quote.prices.fee.postTaxFee,
          value: 0
        }
      },
      notes: quote.notes,
      destination_id: quote.destination_id,
      destination: quote.destination
    };
  }
}
