import EntityMapper from "@/components/inventory/BatchTransfer/EntityMappers/EntityMapper.abstract";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES,
  ExternalBatchTransfer,
  TRANSFER_STATUS,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";
export default class NewBatchTransferMapper extends EntityMapper {
  public mapInput(
    transfer: ExternalBatchTransfer.Input.NewBatchTransfer.NewBatch
  ): BatchTransfer.NewBatch {
    return {
      id: transfer.id,
      barcode: transfer.transfer_id,
      transfer_type: TRANSFER_TYPES.INBOUND,
      source_type: transfer.sourceable_type,
      source_id: transfer.sourceable_id,
      source: transfer.sourceable,
      location_id: transfer.location_id,
      location: transfer.location,
      user_id: transfer.user_id,
      user: transfer.user,
      updated_at: transfer.updated_at,
      is_payable: transfer.is_payable,
      status: transfer.status,
      notes:
        transfer.notes && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `new-batch-${transfer.transfer_id}`,
                title: `new-batch-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      // @ts-ignore --> Diference between API and front end
      items: transfer.transfer_items
        ? transfer.transfer_items.map(item => {
            return {
              id: item.id,
              persisted: true,
              sku: item.product!.sku,
              product: item.product!,
              batch_uid: item.batch_uid,
              batch: item.batch,
              tax_category_id: item.tax_category_id,
              tax_count: {
                pre_excise: 0,
                excise: 0,
                post_excise: 0,
                per_transaction: 0
              },
              quantity: item.quantity,
              prices: {
                price_per_unit: item.price_per_unit,
                cost_per_unit: item.cost_per_unit,
                subTotal: 0,
                total: item.total,
                taxCollected: item.tax_total,
                discount: {
                  description: item.discount.name,
                  amount: item.discount.amount,
                  type: item.discount.type,
                  postTaxDiscount: item.discount.post_tax,
                  value: 0
                },
                fee: {
                  description: item.fees.name,
                  amount: item.fees.amount,
                  type: item.fees.type,
                  postTaxFee: item.fees.post_tax,
                  value: 0
                }
              },
              unit: item.product!.unit,
              expiration_date: item.expiration_date,
              usable_weight_value: item.usable_weight_value,
              usable_weight_unit: item.usable_weight_unit,
              weight_per_unit_value: item.weight_per_unit_value,
              weight_per_unit_unit: item.weight_per_unit_unit,
              lab_results: {
                laboratory_id: 0,
                testing_date: "",
                results: []
              }
            };
          })
        : [],
      pricing_enabled: true,
      prices: {
        total: transfer.total,
        subTotal: transfer.sub_total,
        discount: {
          description: "",
          amount: 0,
          type: DISCOUNT_FEE_TYPES.FIXED,
          postTaxDiscount: true,
          value: 0
        },
        fee: {
          description: "",
          amount: 0,
          type: DISCOUNT_FEE_TYPES.FIXED,
          postTaxFee: true,
          value: 0
        },
        taxCollected: transfer.tax
      },
      payment_methods_breakdown: {
        initial: 0,
        payment_and_methods: transfer.payments.map(payment => {
          return {
            type: payment.payment_method_id,
            applied: payment.amount,
            remaining: 0
          };
        }),
        outstanding: 0
      }
    };
  }
  public mapOutput(
    transfer: BatchTransfer.NewBatch
  ): ExternalBatchTransfer.Output.NewBatchTransfer.NewBatch {
    const mapOutput: ExternalBatchTransfer.Output.NewBatchTransfer.NewBatch = {
      id: transfer.id,
      type: TRANSFER_TYPES.INBOUND,
      sourceable_type: transfer.source_type,
      sourceable_id: transfer.source_id,
      sourceable_transfer_type: transfer.sourceable_transfer_type,
      sub_total: transfer.prices.subTotal,
      total: transfer.prices.total,
      tax: transfer.prices.taxCollected,
      status: TRANSFER_STATUS.COMPLETE,
      is_payable: transfer.is_payable,
      payments: transfer.payment_methods_breakdown.payment_and_methods.map(
        payment => {
          return {
            payment_method_id: payment.type,
            amount: payment.applied
          };
        }
      ),
      discount: {
        name: transfer.prices.discount.description,
        amount: transfer.prices.discount.amount,
        post_tax: transfer.prices.discount.postTaxDiscount,
        type: transfer.prices.discount.type
      },
      fees: {
        name: transfer.prices.fee.description,
        amount: transfer.prices.fee.amount,
        post_tax: transfer.prices.fee.postTaxFee,
        type: transfer.prices.fee.type
      },
      notes:
        transfer.notes && transfer.notes[0] && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `new-batch-${transfer.transfer_id}`,
                title: `new-batch-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      transfer_items: transfer.items.map(item => {
        return {
          marijuana: item.product!.marijuana!,
          strain: item.product!.strain ? item.product!.strain!.name : "",
          batch_type_id: item.product!.batch_type_id,
          sku: item.sku,
          batch_uid: item.batch_uid,
          tracking_id: item.secondary_id,
          biotrack_traceability_id: item.biotrack_traceability_id,
          expiration_date: item.expiration_date,
          batch_price_level_id:
            item.batch_level_pricing && item.batch_level_pricing > -1
              ? item.batch_level_pricing
              : null,
          quantity_value: item.quantity,
          price_per_unit: item.prices.price_per_unit,
          cost_per_unit: item.prices.cost_per_unit,
          subtotal: item.prices.subTotal,
          total: item.prices.total,
          tax_category_id:
            item.tax_category_id! < 0 ? null : item.tax_category_id,
          tax_total: item.prices.taxCollected,
          discount: {
            name: item.prices.discount.description,
            amount: item.prices.discount.amount,
            post_tax: item.prices.discount.postTaxDiscount,
            type: item.prices.discount.type
          },
          fees: {
            name: item.prices.fee.description,
            amount: item.prices.fee.amount,
            post_tax: item.prices.fee.postTaxFee,
            type: item.prices.fee.type
          },
          sample: {
            laboratory_id: item.lab_results.laboratory_id,
            testing_date: item.lab_results.testing_date,
            results: [...item.lab_results.results]
          },
          quantity_unit: item.product!.unit,
          usable_weight_value: item.usable_weight_value,
          usable_weight_unit:
            (item.usable_weight_value && item.usable_weight_unit) || null,
          weight_per_unit_value: item.weight_per_unit_value,
          weight_per_unit_unit: item.weight_per_unit_unit,
          locations: [
            {
              inventory_location_id: item.rooms![0].room_id,
              quantity: item.rooms![0].quantity
            }
          ]
        };
      })
    };
    if (transfer.reconciliation) {
      mapOutput.add_batch_reconciliation = true;
      mapOutput.transfer_items[0].state_reporting_type =
        transfer.items[0].state_reporting_type;
    }
    return mapOutput;
  }
}
