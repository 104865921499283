import EntityMapper from "@/components/inventory/BatchTransfer/EntityMappers/EntityMapper.abstract";
import PurchaseOrderMapper from "@/components/inventory/BatchTransfer/EntityMappers/PurchaseOrder.mapper";
import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES,
  ExternalBatchTransfer,
  TRANSFER_STATUS,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";

export default class InboundTransferMapper extends EntityMapper {
  protected purchaseMapper = new PurchaseOrderMapper();
  public mapInput(
    transfer: ExternalBatchTransfer.Input.InboundTransfer.Inbound
  ): BatchTransfer.InboundTransfer {
    return {
      id: transfer.id,
      sourceable_id: transfer.sourceable_id,
      is_voidable: transfer.is_voidable,
      barcode: transfer.transfer_id,
      transfer_type: TRANSFER_TYPES.INBOUND,
      invoice_id: transfer.invoice_id,
      is_payable: transfer.is_payable,
      transferable_type: transfer.transferable_type,
      transferable_id: transfer.transferable_id,
      transferable: transfer.transferable
        ? this.purchaseMapper.mapInput(
            transfer.transferable as ExternalBatchTransfer.Input.PurchaseOrderTransfer.PurchaseOrder
          )
        : undefined,
      status: transfer.status,
      source_type: transfer.sourceable_type,
      sourceable_transfer_type: transfer.sourceable_transfer_type,
      source_id: transfer.sourceable_id,
      source: transfer.sourceable,
      location_id: transfer.location_id,
      location: transfer.location,
      user_id: transfer.user_id,
      user: transfer.user,
      created_at: transfer.created_at,
      updated_at: transfer.updated_at,
      notes:
        transfer.notes && transfer.notes[0] && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `inbound-transfer-${transfer.transfer_id}`,
                title: `inbound-transfer-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      payments: transfer.payments,
      transfer: {
        items: []
      },
      // @ts-ignore --> Diference between API and front end
      items: transfer.transfer_items
        ? transfer.transfer_items.map(item => {
            return {
              id: item.id,
              persisted: true,
              sku: (item.product && item.product.sku) || item.sku,
              product: item.product!,
              batch_uid: item.batch_uid,
              batch: item.batch,
              tax_category_id: item.tax_category_id,
              tax_count: {
                pre_excise: 0,
                excise: 0,
                post_excise: 0,
                per_transaction: 0
              },
              quantity: item.quantity_value,
              prices: {
                price_per_unit: item.price_per_unit,
                cost_per_unit: item.cost_per_unit,
                subTotal: 0,
                total: item.total,
                taxCollected: item.tax_total,
                discount: {
                  description: item.discount.name,
                  amount: item.discount.amount,
                  type: item.discount.type,
                  postTaxDiscount: item.discount.post_tax,
                  value: 0
                },
                fee: {
                  description: item.fees.name,
                  amount: item.fees.amount,
                  type: item.fees.type,
                  postTaxFee: item.fees.post_tax,
                  value: 0
                }
              },
              expiration_date: item.expiration_date,
              usable_weight_value: item.usable_weight_value,
              usable_weight_unit: item.usable_weight_unit,
              unit: item.product!.unit,
              weight_per_unit_value: ((item as unknown) as BatchTransfer.Batch)
                .weight_per_unit_value,
              weight_per_unit_unit: ((item as unknown) as BatchTransfer.Batch)
                .weight_per_unit_unit,
              lab_results: {
                laboratory_id: 0,
                testing_date: "",
                results: []
              },
              locations: [
                item.batch && item.batch.summary && item.batch.summary.length
                  ? {
                      inventory_location_id: item.batch.summary![0]
                        .inventory_location.id!,
                      quantity: item.batch!.summary![0].quantity_value!
                    }
                  : {
                      inventory_location_id: -1,
                      quantity: 0
                    }
              ]
            };
          })
        : [],
      pricing_enabled: true,
      prices: {
        total: transfer.total,
        subTotal: transfer.sub_total,
        discount: {
          description: transfer.discount.name,
          amount: transfer.discount.amount,
          type: transfer.discount.type,
          postTaxDiscount: transfer.discount.post_tax,
          value: transfer.discount.amount
        },
        fee: {
          description: transfer.fees.name,
          amount: transfer.fees.amount,
          type: transfer.fees.type,
          postTaxFee: transfer.fees.post_tax,
          value: transfer.fees.amount
        },
        taxCollected: transfer.tax
      },
      payment_methods_breakdown: {
        initial: 0,
        payment_and_methods: transfer.payments.map(payment => {
          return {
            type: payment.payment_method_id,
            applied: payment.amount,
            remaining: 0
          };
        }),
        outstanding: 0
      }
    };
  }
  // @ts-ignore
  public mapOutput(
    transfer: BatchTransfer.InboundTransfer,
    isEdit: boolean = false
  ): ExternalBatchTransfer.Output.InboundTransfer.Inbound {
    return {
      id: transfer.id,
      type: TRANSFER_TYPES.INBOUND,
      transferable_type: transfer.manifest_id ? "" : transfer.transferable_type,
      transferable_id: transfer.transferable_id || "",
      manifest_id: transfer.manifest_id,
      sourceable_type: transfer.source_type,
      sourceable_transfer_type: transfer.sourceable_transfer_type,
      sourceable_id: transfer.source_id,
      sub_total: transfer.prices.subTotal,
      is_payable: transfer.is_payable,
      total: transfer.prices.total,
      tax: transfer.prices.taxCollected,
      status: TRANSFER_STATUS.COMPLETE,
      payments: transfer.prices.total
        ? transfer.payment_methods_breakdown.payment_and_methods.map(
            payment => {
              return {
                payment_method_id: payment.type,
                amount: payment.applied
              };
            }
          )
        : [],
      discount: {
        name: transfer.prices.discount.description,
        amount: transfer.prices.discount.amount,
        post_tax: transfer.prices.discount.postTaxDiscount,
        type: transfer.prices.discount.type
      },
      fees: {
        name: transfer.prices.fee.description,
        amount: transfer.prices.fee.amount,
        post_tax: transfer.prices.fee.postTaxFee,
        type: transfer.prices.fee.type
      },
      notes:
        transfer.notes && transfer.notes[0] && !!transfer.notes[0].note
          ? transfer.notes.map(note => {
              return {
                id: note.id,
                name: `inbound-transfer-${transfer.transfer_id}`,
                title: `inbound-transfer-${transfer.transfer_id}-${
                  transfer.updated_at
                }`,
                note: note.note
              };
            })
          : [],
      transfer_items: transfer.items.map(item => {
        let withoutManifest = {
          marijuana: item.product!.marijuana!,
          strain: item.product!.strain ? item.product!.strain!.name : "",
          batch_type_id: item.product!.batch_type_id || item.batch_type_id,
          sku: item.product!.sku,
          id: isEdit ? item.id : undefined,
          batch_uid: item.batch_uid || undefined,
          expiration_date: item.expiration_date,
          batch_price_level_id:
            item.batch_level_pricing && item.batch_level_pricing > -1
              ? item.batch_level_pricing
              : null,
          quantity_value: transfer.manifest_id
            ? Number(item.quantity)
            : item.quantity,
          price_per_unit: item.prices.price_per_unit,
          cost_per_unit: item.prices.cost_per_unit,
          subtotal: item.prices.subTotal,
          total: item.prices.total,
          tax_category_id:
            item.tax_category_id && item.tax_category_id > -1
              ? item.tax_category_id
              : null,
          tax_total: item.prices.taxCollected,
          discount: {
            name: item.prices.discount.description,
            amount: item.prices.discount.amount,
            post_tax: item.prices.discount.postTaxDiscount,
            type: item.prices.discount.type
          },
          fees: {
            name: item.prices.fee.description,
            amount: item.prices.fee.amount,
            post_tax: item.prices.fee.postTaxFee,
            type: item.prices.fee.type
          },
          sample: {
            laboratory_id: String(item.lab_results!.laboratory_id),
            testing_date: item.lab_results!.testing_date,
            results: transfer.manifest_id
              ? item.lab_results!.results
              : [...item.lab_results!.results]
          },
          quantity_unit: item.product!.unit,
          usable_weight_value: item.usable_weight_value,
          usable_weight_unit:
            (item.usable_weight_value && item.usable_weight_unit) || null,
          weight_per_unit_value: item.weight_per_unit_value,
          weight_per_unit_unit: item.weight_per_unit_unit,
          locations: [
            {
              inventory_location_id: item.rooms![0].room_id,
              quantity: transfer.manifest_id
                ? Number(item.quantity)
                : item.rooms![0].quantity
            }
          ]
        };
        if (transfer.manifest_id && !item.product!.marijuana) {
          withoutManifest = withoutManifest;
        } else {
          withoutManifest = {
            ...withoutManifest,
            // @ts-ignore --> Diference between API and front end
            biotrack_traceability_id: item.barcode_id || item.id
          };
        }
        return withoutManifest;
      })
    };
  }
  public converteOutboundTransfer(
    transfer: BatchTransfer.OutboundTransfer
  ): Partial<BatchTransfer.InboundTransfer> {
    return {
      transfer_type: TRANSFER_TYPES.INBOUND,
      items: transfer.items,
      prices: transfer.prices,
      source_id: transfer.location_id,
      source: transfer.location
    };
  }
  public convertPurchaseOrder(
    purchaseOrder: BatchTransfer.PurchaseOrder
  ): Partial<BatchTransfer.InboundTransfer> {
    return {
      transfer_type: purchaseOrder.transfer_type,
      items: purchaseOrder.items.map(item => {
        return {
          ...item,
          expiration_date: "",
          lab_results: {
            laboratory_id: 0,
            testing_date: "",
            results: []
          }
        };
      }),
      prices: {
        subTotal: purchaseOrder.prices.subTotal,
        total: purchaseOrder.prices.total,
        taxCollected: purchaseOrder.prices.taxCollected,
        discount: {
          description: purchaseOrder.prices.discount.description,
          amount: purchaseOrder.prices.discount.amount,
          type: purchaseOrder.prices.discount.type,
          postTaxDiscount: purchaseOrder.prices.discount.postTaxDiscount,
          value: 0
        },
        fee: {
          description: purchaseOrder.prices.fee.description,
          amount: purchaseOrder.prices.fee.amount,
          type: purchaseOrder.prices.fee.type,
          postTaxFee: purchaseOrder.prices.fee.postTaxFee,
          value: 0
        }
      },
      source_id: purchaseOrder.source_id,
      source: purchaseOrder.source
    };
  }
}
