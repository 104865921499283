import {
  BatchTransfer,
  ExternalBatchTransfer
} from "@/interfaces/batchTransferManager";

export default abstract class EntityMapper {
  public abstract mapInput(
    batch: ExternalBatchTransfer.Transfer
  ): BatchTransfer.Transfer;
  public abstract mapOutput(
    batch: BatchTransfer.Transfer
  ): ExternalBatchTransfer.Transfer;
}
